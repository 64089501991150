import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import './IgBanner.css'

const IgBanner = ({ bannerImg }) => {
  let history = useHistory();

  useEffect(() => {
    if (!bannerImg) {
      history.push("/")
    }
  }, [bannerImg, history])

  return (
    // <div className="ig-banner">
    //   <div data-testid="backgroundImage" className={bannerImg + " image "} ></div>
    // </div >
    <div className="ig-banner">
      <img src={bannerImg} alt="patterned background in brand colors" />
    </div>
  );
};

export default IgBanner;

IgBanner.propTypes = {
  bannerImg: PropTypes.string,
};

