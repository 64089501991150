import React from 'react';
import PropTypes from "prop-types";
import DropZone from "../DropZone/DropZone";
import IgBanner from "../Banner/IgBanner";

import "./IgTemplate.css";

const IgTemplateView = ({ dropZoneMethods, fileRef, templateData }) => {
  return (
    <div className="main-area">
      <div className="ig-template-container">
        <IgBanner bannerImg={templateData.imgSrc} />
        <DropZone
          onclick={dropZoneMethods.onClick}
          inputRef={fileRef}
          onDrop={dropZoneMethods.onDrop}
          onEnter={dropZoneMethods.onEnter}
          onLeave={dropZoneMethods.onLeave}
          onOver={dropZoneMethods.onOver}
          onChange={dropZoneMethods.onChange}
        />
        <div className="logo">
          <img src={templateData.logo} alt="thoughtworks logo" className="logo-image" />
        </div>
      </div>
      <div className="ig-template disclaimer-text">
        <p className="text">
          Disclaimer: <br /> It must be a picture of yourself. No other type of
          pictures are allowed. You must retain the rights over image use.
        </p>
      </div>
    </div>
  );
}

IgTemplateView.propTypes = {
  onClick: PropTypes.func,
  fileRef: PropTypes.object,
  onDrop: PropTypes.func,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onOver: PropTypes.func,
  onChange: PropTypes.func,
  templateData: PropTypes.object,
  dropZoneMethods: PropTypes.object
};

export default IgTemplateView;
