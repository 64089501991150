import React from "react";
import PropTypes from "prop-types";
import CampaignSelector from "../CampaignSelector/CampaignSelector";
import "./Footer.css";

export default function Footer( {setCampaign } ) {
  function handleChange(event) {
    setCampaign(event.target.value);
  }

  return (
    <div className="footer" data-testid="footer">
      <div className="link-container">
      <CampaignSelector handleChange={handleChange} />
      </div>
    </div>
  );
}

Footer.propTypes = {
  setCampaign: PropTypes.func,
};