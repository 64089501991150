import React from 'react';
import PropTypes from "prop-types";
import DropZone from "../DropZone/DropZone";
import Banner from "../Banner/Banner";

import "./Template.css";

const TemplateView = ({ dropZoneMethods, fileRef, templateData }) => {
  return (
    <div className="main-area">
      <div className="template-container">
        <DropZone
          onclick={dropZoneMethods.onClick}
          inputRef={fileRef}
          onDrop={dropZoneMethods.onDrop}
          onEnter={dropZoneMethods.onEnter}
          onLeave={dropZoneMethods.onLeave}
          onOver={dropZoneMethods.onOver}
          onChange={dropZoneMethods.onChange}
        />
        <Banner bannerImg={templateData.imgSrc} logo={templateData.logo} />
      </div>
      <div className="template disclaimer-text">
        <p className="text">
          Disclaimer: <br /> It must be a picture of yourself. No other type of
          pictures are allowed. You must retain the rights over image use.
        </p>
      </div>
    </div>
  );
}

TemplateView.propTypes = {
  onClick: PropTypes.func,
  fileRef: PropTypes.object,
  onDrop: PropTypes.func,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onOver: PropTypes.func,
  onChange: PropTypes.func,
  templateData: PropTypes.object,
  dropZoneMethods: PropTypes.object
};

export default TemplateView;
