import React from "react";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import { config } from "./authConfig";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import originalImages from "./originalAssets";
// import impactImages from "./impactAssets";
// import { ImagesProvider } from "./ImagesContext";




// const withAssets = (Component, campaign) => {
  // let images = "";
  
  // switch(campaign)
  //     {
  //     case "original": images = originalImages;
  //     break;
  //     case "impact": images = impactImages;
  //     break;
  //     default: {images = impactImages}
  //   } 
  // const AssetsWrapped = (props) => {
  //   return (
  //     <ImagesProvider value={images}>
  //       <Component {...props} />
  //     </ImagesProvider>
  //   );
  // };

  // return AssetsWrapped;
// };

const withRouting = (Component) => {
  const RouteWrapped = (props) => {
    return (
      <BrowserRouter>
        <Component {...props} />
      </BrowserRouter>
    );
  };

  return RouteWrapped;
};


const withAuth = (Component) => {
  const Entry = () => {
    const history = useHistory();
    const oktaAuth = new OktaAuth(config);

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    };
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <SecureRoute path="/" component={Component} />
        <Route path={process.env.REACT_APP_CALLBACK_PATH} component={LoginCallback} />
      </Security>
    );
  };
  return Entry;
};

export { withRouting, withAuth };
