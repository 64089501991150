import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import './Banner.css'

const Banner = ({ bannerImg, logo }) => {
  let history = useHistory();

  useEffect(() => {
    if (!bannerImg) {
      history.push("/")
    }
  }, [bannerImg, history])

  return (
    <div className="banner">
      <div className="logo">
        <img src={logo} alt="thoughtworks logo" className="logo-image" />
      </div>
      <div className="logo">
        <img src={bannerImg} alt="patterned background in brand colors" className="logo-image" />
      </div>
    </div >
  );
};

export default Banner;

Banner.propTypes = {
  bannerImg: PropTypes.string,
  logo: PropTypes.string
};

