// static assets
import headline1 from "./assets/fb_1_headline.jpg";
import headline2 from "./assets/fb_2_headline.jpg";
import headline3 from "./assets/fb_3_headline.jpg";
import headline4 from "./assets/fb_4_headline.jpg";


import igIllustration1 from "./assets/ig_1_illustration.jpg";
import igIllustration2 from "./assets/ig_2_illustration.jpg";
import igIllustration3 from "./assets/ig_3_illustration.jpg";
import igIllustration4 from "./assets/ig_4_illustration.jpg";


import fblogo1 from "./assets/fb_1_logo.jpg";
import fblogo2 from "./assets/fb_2_logo.jpg";
import fblogo3 from "./assets/fb_3_logo.jpg";
import fblogo4 from "./assets/fb_4_logo.jpg";

import igWaveLogo from "./assets/ig_headline.jpg";

const images = {
  illustration1: fblogo1,
  illustration2: fblogo2,
  illustration3: fblogo3,
  illustration4: fblogo4,
  igIllustration1: igIllustration1,
  igIllustration2: igIllustration2,
  igIllustration3: igIllustration3,
  igIllustration4: igIllustration4,
  fblogo1: headline1,
  fblogo2: headline2,
  fblogo3: headline3,
  fblogo4:headline4,
  igLogo1: igWaveLogo,
  igLogo2: igWaveLogo,
  igLogo3: igWaveLogo,
  igLogo4: igWaveLogo,
  impact: true,
};

export default images;