import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Panel from "../components/Panel/Panel";
import Template from "../components/Template/Template";
import Canvas from "../components/Canvas/Canvas";
import ImageCropper from "../components/ImageCropper/ImageCropper";
import Selector from "../components/Selector/Selector";
import "../App.css";


function SocialAmplificationTool({campaignHandler, campaign}) {
  const [imageToShare, setImageToShare] = useState();
  const [crop, setCrop] = useState({
    width: null,
    aspect: null,
  });
  const [dimensions, setDimensions] = useState({});
  const [templateData, setTemplateData] = useState({
    banner: null,
    logo: null,
    file: null,
    imgScr: null,
  });
  const [panelText, setPanelText] = useState();
  const [shareable, setShareable] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (location === "/canvas") {
      setPanelText(<p>Ready to share</p>);
      setShareable(true);
    } else {
      setPanelText(
        <p>
          Create your own <br /> personalized card <br /> to share on social
        </p>
      );
      setShareable(false);
    }
  }, [location]);

  useEffect(() => {
    if (templateData.type === "main") {
      setCrop({
        width: 227,
        aspect: 0.89 / 1,
      });
    } else {
      setCrop({
        width: 270,
        aspect: 1 / 1,
      });
    }
  }, [templateData.type, setCrop]);

  const handleSetTemplateData = (newData) => {
    setTemplateData({ ...templateData, ...newData });
  };

  const handleSetLocation = (location) => {
    setLocation(location);
  };

  const handleSetImageToShare = (dataURL) => {
    setImageToShare(dataURL);
  };

  const handleSetShareable = (shareable) => {
    setShareable(shareable);
  };
  const handleSetCrop = (crop) => {
    setCrop(crop);
  };
  const handleSetDimensions = (dimensions) => {
    setDimensions(dimensions);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Header setCampaign={campaignHandler} campaign={campaign} location={location}/>
      </header>

      <main>
        <Panel
          text={panelText}
          imageToShare={imageToShare}
          shareable={shareable}
          setShareable={handleSetShareable}
          location={location}
          setCrop={handleSetCrop}
          setTemplateData={handleSetTemplateData}
          templateData={templateData}
        />

        <Switch>
          <Route exact path="/">
            <Selector
              setLocation={handleSetLocation}
              setTemplateData={handleSetTemplateData}
            />
          </Route>
          <Route exact path="/template">
            <Template
              setLocation={handleSetLocation}
              templateData={templateData}
              setTemplateData={handleSetTemplateData}
            />
          </Route>
          <Route exact path="/canvas">
            <Canvas
              setLocation={handleSetLocation}
              setImageToShare={handleSetImageToShare}
              crop={crop}
              dimensions={dimensions}
              templateData={templateData}
              setTemplateData={handleSetTemplateData}
            />
          </Route>
          <Route exact path="/crop">
            <ImageCropper
              setLocation={handleSetLocation}
              crop={crop}
              setCrop={handleSetCrop}
              setDimensions={handleSetDimensions}
              templateData={templateData}
            />
          </Route>
        </Switch>
      </main>

      <div className="clear"></div>
      {location==="/"&& <footer>
        <Footer setCampaign={campaignHandler} />
      </footer>}
    </div>
  );
}

export default SocialAmplificationTool;

SocialAmplificationTool.propTypes = {
  campaignHandler: PropTypes.func,
  campaign: PropTypes.string,
};
