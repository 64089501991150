import React from "react";
import PropTypes from "prop-types";



export default function BackNav({ onClick }) {
  return (
    <a href="/" className="back-nav-container" onClick={onClick}>
      <div className="reverse-chevron"></div>
      <div className="back-nav">Back</div>
    </a>
  )
}

BackNav.propTypes = {
  onClick: PropTypes.func
};