import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageCropper.css'

export default function ImageCropper({ setLocation, crop, setCrop, setDimensions, templateData }) {
  const [imgSrc, setImgSrc] = useState();
  let history = useHistory();

  let location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  }, [location, setLocation]);

  const img = useMemo(() => new Image(), []);
  img.src = imgSrc;

  useEffect(() => {
    if (templateData.file && !imgSrc) {
      setImgSrc(templateData.file);
    }
    else if (!templateData.file && !imgSrc) {
      history.push("/template");
    }
  }, [history, imgSrc, templateData.file]);

  const onClick = (e) => {
    e.preventDefault();
    history.push('/canvas')
  }

  const onImageLoaded = (image) => {
    setDimensions({
      width: image.width,
      height: image.height
    })
  }

  return (
    <div className="main-area image-cropper">
      <div className="cropper">
        <ReactCrop
          src={imgSrc}
          crop={crop}
          onChange={newCrop => { setCrop(newCrop) }}
          imageStyle={{
            maxWidth: "50vw",
            maxHeight: "50vh"
          }}
          onImageLoaded={onImageLoaded}
        />
      </div>
      <div className="instruction">
        <p className="instruction-text">
          Please select the display area for your image - then press the crop button to continue.
          </p>

      </div>
      <button
        onClick={onClick}
        className="button"
      > Crop </button>
    </div>

  )

}

ImageCropper.propTypes = {
  setLocation: PropTypes.func,
  crop: PropTypes.object,
  setCrop: PropTypes.func,
  setDimensions: PropTypes.func,
  templateData: PropTypes.object,
};
