/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
import PropTypes from "prop-types";

import "../Header/Header.css";

export default function CampaignSelector({ campaign, handleChange }) {

  return (
        <form>
      <label htmlFor="campaigns" className="link">Choose a campaign </label>
        <select name="campaigns" id="campaigns" className="chevron" defaultValue={campaign} onChange={handleChange}>
          <option value="original">Original</option>
          <option value="impact">Current Global</option>
          <option value="thirtieth">30th Anniversary</option>
        </select>
        </form>

  );
}

CampaignSelector.propTypes = {
  campaign: PropTypes.string,
  handleChange: PropTypes.func,
};