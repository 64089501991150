import logo1 from "./assets/thirtieth/general_1-0.png"
import illustration1 from "./assets/thirtieth/general_1-1.png"
import logo2 from "./assets/thirtieth/general_2-0.png"
import illustration2 from "./assets/thirtieth/general_2-1.png"
import logo3 from "./assets/thirtieth/general_3-0.png"
import illustration3 from "./assets/thirtieth/general_3-1.png"
import logo4 from "./assets/thirtieth/general_4-0.png"
import illustration4 from "./assets/thirtieth/general_4-1.png"

import igIllustration1 from "./assets/thirtieth/ig_1-0.png"
import igIllustration2 from "./assets/thirtieth/ig_2-0.png"
import igIllustration3 from "./assets/thirtieth/ig_3-0.png"
import igIllustration4 from "./assets/thirtieth/ig_4-0.png"

import twThirtyLogo from "./assets/thirtieth/ig_1-1.png"
import twGreyLogo from "./assets/thirtieth/ig_2-1.png"
import twWaveLogo from "./assets/thirtieth/ig_3-1.png"



const images = {
    illustration1,
    illustration2,
    illustration3,
    illustration4,
    fblogo1: logo1,
    fblogo2: logo2,
    fblogo3: logo3,
    fblogo4: logo4,
    igIllustration1,
    igIllustration2,
    igIllustration3,
    igIllustration4,
    igLogo1: twThirtyLogo,
    igLogo2: twGreyLogo,
    igLogo3: twWaveLogo,
    igLogo4: twWaveLogo,
    thirtieth: true,
};
  
  export default images;