import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Template.css";
import PropTypes from "prop-types";
import TemplateView from "./TemplateView";
import IgTemplateView from "./IgTemplateView";

const Template = ({ setLocation, templateData, setTemplateData }) => {
  const fileRef = useRef("fileInput");
  const [uploadedFile, setUploadedFile] = useState();
  const useMainTemplate = templateData.type === "main" ? true : false;
  let history = useHistory();
  let location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  }, [location.pathname, setLocation]);

  useEffect(() => {
    if (uploadedFile) {
      let reader = new FileReader();
      reader.onload = function () {
        setTemplateData({ file: reader.result });
      };
      reader.readAsDataURL(uploadedFile);
    }
  }, [uploadedFile, setTemplateData]);

  useEffect(() => {
    if (templateData.file) {
      history.push("/crop");
    } else if (templateData.fromCanvas === true) {
      history.push("/")
    }
  }, [templateData.file, templateData.fromCanvas, history]);


  const onClick = (e) => {
    e.preventDefault();
    fileRef.current.click();
  };

  const onChange = (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files && files.length) {
      const targetFile = files[0];
      setUploadedFile(targetFile);
    }
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    const targetFile = event.dataTransfer.files[0];
    const typeMatch = targetFile.type.match(/^image/)
    if (typeMatch) {
      setUploadedFile(targetFile);
    } else { alert("file type not accepted. Please upload an .jpg, .jpeg or .png file") }
  };

  const dragEnter = (e) => {
    e.preventDefault();
    const dragEffect = (e.dataTransfer.dropEffect = "move");
    return dragEffect;
  };

  const dragLeave = (e) => {
    e.preventDefault();
    const dragLeave = (e.dataTransfer.dropEffect = "none");
    return dragLeave;
  };

  const dragOver = (e) => {
    e.preventDefault();
  };


  const dropZoneMethods = {
    onClick: onClick,
    inputRef: fileRef,
    onDrop: handleFileUpload,
    onEnter: dragEnter,
    onLeave: dragLeave,
    onOver: dragOver,
    onChange: onChange,
  }
  return (
    <>
      {useMainTemplate && <TemplateView
        templateData={templateData}
        fileRef={fileRef}
        dropZoneMethods={dropZoneMethods}
      />
      }
      {!useMainTemplate &&
        <IgTemplateView
          templateData={templateData}
          fileRef={fileRef}
          dropZoneMethods={dropZoneMethods}
        />
      }
    </>
  );
};

Template.propTypes = {
  fileInput: PropTypes.object,
  setFile: PropTypes.func,
  file: PropTypes.string,
  bannerImg: PropTypes.string,
  setLocation: PropTypes.func,
  setTemplateData: PropTypes.func,
  templateData: PropTypes.object
};

export default Template;
