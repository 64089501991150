const configDev = {
  clientId: process.env.REACT_APP_CLIENT_ID_DEV,
  issuer: process.env.REACT_APP_ISSUER_DEV,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile", "email"],
  pkce: true,
};
const configProd = {
  clientId: process.env.REACT_APP_CLIENT_ID_PROD,
  issuer: process.env.REACT_APP_ISSUER_PROD,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile", "email"],
  pkce: true,
};
const config = process.env.NODE_ENV !== "production" ? configDev : configProd;

export { config };
