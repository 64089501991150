import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./Canvas.css";

export default function CanvasView({ draw, ready, ...rest }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    if (ready) {
      draw(context);
    }
  }, [draw, ready]);

  return (
    <div className="canvas-container" data-testid="canvas" >
      <canvas ref={canvasRef} {...rest} />
    </div>
  );
}

CanvasView.propTypes = {
  draw: PropTypes.func,
  ready: PropTypes.bool,
};
