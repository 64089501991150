import React, { useEffect, useState, useMemo, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import CanvasView from "./CanvasView";
import ImagesContext from '../../ImagesContext';
import 'react-image-crop/dist/ReactCrop.css';

export default function Canvas({ dimensions, setLocation, setImageToShare, crop, templateData, setTemplateData }) {
    const [imgSrc, setImgSrc] = useState();
    const [imgReady, setImgReady] = useState(false);
    const [logoReady, setLogoReady] = useState(false);
    const [bannerReady, setBannerReady] = useState(false);
    const [readyToDraw, setReadyToDraw] = useState(false);
    const [dataUrl, setDataUrl] = useState();

    const images = useContext(ImagesContext)
    let history = useHistory();

    let location = useLocation();
    useEffect(() => {
        setLocation(location.pathname);
    }, [location, setLocation]);

    useEffect(() => {
        if (dataUrl) {
            setImageToShare(dataUrl)
        }
    }, [dataUrl, setImageToShare])

    const bannerImg = useMemo(() => new Image(), []);

    switch (templateData.banner) {
        case "illustration1":
            templateData.type === "main" ?
                bannerImg.src = images.illustration1 : bannerImg.src = images.igIllustration1;
            break;
        case "illustration2":
            templateData.type === "main" ?
                bannerImg.src = images.illustration2 : bannerImg.src = images.igIllustration2;
            break;
        case "illustration3":
            templateData.type === "main" ?
                bannerImg.src = images.illustration3 : bannerImg.src = images.igIllustration3;
            break;
        case "illustration4":
            templateData.type === "main" ?
                bannerImg.src = images.illustration4 : bannerImg.src = images.igIllustration4;
            break;
        default: bannerImg.src = images[0];
    }
    bannerImg.addEventListener('load', function () { setBannerReady(true) });

    const logoImg = useMemo(() => new Image(), []);
    logoImg.src = templateData.logo;
    logoImg.addEventListener('load', function () { setLogoReady(true) });


    const img = useMemo(() => new Image(), []);
    img.src = imgSrc;
    img.addEventListener('load', function () { setImgReady(true) });


    useEffect(() => {
        if (templateData.file && !imgSrc) {
            setImgSrc(templateData.file);
        } else if (templateData.file && imgSrc) {
            setTemplateData({ file: null })
        } else if (!templateData.file && imgSrc) {
            return;
        } else {
            history.push("/template");
        }
    }, [templateData.file, history, setTemplateData, imgSrc]);

    useEffect(() => {
        if (bannerReady && logoReady && imgReady) {
            setReadyToDraw(true)
        }
    }, [bannerReady, logoReady, imgReady])

    function drawMain(ctx) {
        ctx.canvas.style.width = 1816 + "px";
        ctx.canvas.style.height = 1024 + "px";
        // var scale = window.devicePixelRatio;
        ctx.canvas.width = 1816;
        ctx.canvas.height = 1024;
        let picWidth = ctx.canvas.width / 2;
        let picHeight = ctx.canvas.height;
        let bgWidth = ctx.canvas.width - picWidth;
        const scaleX = img.naturalWidth / dimensions.width;
        const scaleY = img.naturalHeight / dimensions.height;
        // draw banner image
        ctx.drawImage(bannerImg, picWidth, picHeight / 2, bgWidth, picHeight / 2);
        // draw photo
        ctx.drawImage(img, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, picWidth, picHeight);
        //draw logo
        ctx.drawImage(logoImg, picWidth, 0, bgWidth, picHeight / 2);
        // blue filter #003d4f
        ctx.globalCompositeOperation = "screen";
        ctx.fillStyle = "rgba(0,61,79,0.6)";
        ctx.fillRect(0, 0, picWidth, picHeight);
        // pink filter #f2617a
        ctx.globalCompositeOperation = "overlay";
        ctx.fillStyle = "rgba(242,97,122,0.1)";
        ctx.fillRect(0, 0, picWidth, picHeight);
        setDataUrl(ctx.canvas.toDataURL())
    }

    function drawIg(ctx) {
        ctx.canvas.style.width = 1080 + "px";
        ctx.canvas.style.height = 1080 + "px";
        // var scale = window.devicePixelRatio;
        ctx.canvas.width = 1080;
        ctx.canvas.height = 1080;
        let picWidth = ctx.canvas.width / 2;
        let picHeight = ctx.canvas.height / 2;
        const scaleX = img.naturalWidth / dimensions.width;
        const scaleY = img.naturalHeight / dimensions.height;
        // draw banner image
        ctx.drawImage(bannerImg, 0, 0, picWidth, picHeight);
        // draw photo
        ctx.drawImage(img, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, picWidth, 0, picWidth, picHeight);
        //draw logo
        ctx.drawImage(logoImg, 0, picHeight, picWidth * 2, picHeight);
        // blue filter #003d4f
        ctx.globalCompositeOperation = "screen";
        ctx.fillStyle = "rgba(0,61,79,0.6)";
        ctx.fillRect(picWidth, 0, picWidth, picHeight);
        // pink filter #f2617a
        ctx.globalCompositeOperation = "overlay";
        ctx.fillStyle = "rgba(242,97,122,0.1)";
        ctx.fillRect(picWidth, 0, picWidth, picHeight);
        setDataUrl(ctx.canvas.toDataURL())
    }

    const draw = (ctx) => {
        if (templateData.type === "instagram") {
            drawIg(ctx)
        } else {
            drawMain(ctx)
        }
    };

    return (
        <>
            <CanvasView draw={draw} ready={readyToDraw} />
            <div className="main-area">
                <img
                    src={dataUrl}
                    alt="banner with uploaded file set beside selected background and TW logo"
                    className={templateData.type + " final-image"}
                />
                <div className={templateData.type + " disclaimer-text"}>
                    <p className="text">
                        Disclaimer: <br /> It must be a picture of yourself. No other type
                        of pictures are allowed. You must retain the rights over image use.
                    </p>
                </div>
            </div>
        </>
    )

}

Canvas.propTypes = {
    crop: PropTypes.object,
    setLocation: PropTypes.func,
    setImageToShare: PropTypes.func,
    templateData: PropTypes.object,
    setTemplateData: PropTypes.func,
    dimensions: PropTypes.object
};
