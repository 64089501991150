// static assets
import illustration1 from "./assets/illustration-1@2x.jpg";
import illustration2 from "./assets/illustration-2@2x.jpg";
import illustration3 from "./assets/illustration-3@2x.jpg";
import illustration4 from "./assets/illustration-4@2x.jpg";
import igIllustration1 from "./assets/illustration-1-instagram.png";
import igIllustration2 from "./assets/illustration-2-instagram.png";
import igIllustration3 from "./assets/illustration-3-instagram.png";
import igIllustration4 from "./assets/illustration-4-instagram.png";
import igWaveLogo from "./assets/instagram-thoughtworks-logo-wave-bg@2x.png";
import igWhiteLogo from "./assets/instagram-thoughtworks-logo-white-bg@2x.png";
import waveLogo from "./assets/tw-logo-wave-bg.png";
import whiteLogo from "./assets/tw-logo-white-bg.png";

const images = {
  illustration1: illustration1,
  illustration2: illustration2,
  illustration3: illustration3,
  illustration4: illustration4,
  igIllustration1: igIllustration1,
  igIllustration2: igIllustration2,
  igIllustration3: igIllustration3,
  igIllustration4: igIllustration4,
  fblogo1: waveLogo,
  fblogo2: waveLogo,
  fblogo3: whiteLogo,
  fblogo4: whiteLogo,
  igLogo1: igWaveLogo,
  igLogo2: igWaveLogo,
  igLogo3: igWhiteLogo,
  igLogo4: igWhiteLogo,
  original: true
};

export default images;